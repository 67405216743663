import React, { FC, useEffect, useState } from 'react'
import UserApi from 'api/user'
import TeamsApi from 'api/teams'
import UserTeamsApi from 'api/userTeams'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import useAbstractProvider from 'providers/AbstractProvider'
import { useParams } from 'react-router-dom'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import CustomSwitch from "components/atoms/CustomSwitch/CustomSwitch"
import useAbstractMutator from 'providers/AbstractMutator'
import { showToast } from 'utils'
import AdminLayout from 'layouts/AdminLayout'
import { useTranslation } from 'react-i18next'
import CancelIcon from '@material-ui/icons/Cancel'
import MaterialSelect from "components/atoms/MaterialSelect/MaterialSelect"
import { Skeleton } from '@material-ui/lab'
import moment from 'moment'

interface ParamsProps {
  userId: string
}

const useStyles = makeStyles((theme) => ({
  mainHeading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  search: {
    marginBottom: 15,
  },
  table: {
    paddingBottom: 70,
  },
  teams: {
    marginTop: 30,
    marginBottom: 30,
  },
  heading: {
    marginBottom: 15
  },
  activity: {
    marginTop: 30,
    fontSize: '1rem',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  values: {
    fontWeight: 600
  },
  active: {
    color: theme.palette.success.main
  },
  inactive: {
    color: theme.palette.error.main
  },
  cancelIcon: {
    marginRight: '5px',
    color: theme.palette.error.main,
    cursor: 'pointer'
  },
  team: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem'
  },
  select: {
    height: '20px',
    padding: '12px',
  }
}))

interface UserDetailPageProps { }

const UserDetailPage: FC<UserDetailPageProps> = () => {
  const classes = useStyles()
  const { userId } = useParams<ParamsProps>()
  const [active, setActive] = useState<boolean>(false)
  const [teamIdToAdd, setTeamIdToAdd] = useState<number>(-1)
  const [teamOptions, setTeamOptions] = useState<any>(null)

  const [data, setData] = useState(null)

  const user = useAbstractProvider(UserApi.getUserById, { userId }, false)
  const teams = useAbstractProvider(TeamsApi.getUsersTeams, { userId }, false)
  const teamsUserIsNotIn = useAbstractProvider(TeamsApi.getTeamsUserIsNotIn, { userId }, false)
  const addUserTeam = useAbstractMutator(UserTeamsApi.addUserToTeam)
  const removeUserTeam = useAbstractMutator(UserTeamsApi.removeUserToTeam)
  const deactivateUserMutator = useAbstractMutator(UserApi.deactivateUser)
  const activateUserMutator = useAbstractMutator(UserApi.activateUser)

  const { t } = useTranslation()

  useEffect(() => {
    if (userId) {
      user.refetch(userId)
      teams.refetch(userId)
      teamsUserIsNotIn.refetch(userId)
    }
  }, [userId])

  useEffect(() => {
    if (user?.data) {
      const newUserData = user.data
      if (newUserData.ssoData) {
        delete newUserData.ssoData?.email
        delete newUserData.ssoData?.given_name
        delete newUserData.ssoData?.family_name
      }
      setData(user?.data)
      setActive(user?.data?.active)
    }
  }, [user.data])

  useEffect(() => {
    if (addUserTeam?.data) {
      teams.refetch(userId)
      teamsUserIsNotIn.refetch(userId)
      showToast(t('admin_users_and_teams.add_to_team_success_msg'))
      setTeamIdToAdd(-1)
    }
  }, [addUserTeam.data])

  useEffect(() => {
    if (removeUserTeam?.data) {
      teams.refetch(userId)
      teamsUserIsNotIn.refetch(userId)
      showToast(t('admin_users_and_teams.remove_from_team_success_msg'))
    }
  }, [removeUserTeam.data])

  useEffect(() => {
    if (deactivateUserMutator?.data || activateUserMutator?.data) {
      window.location.reload()
    }
  }, [deactivateUserMutator, activateUserMutator])

  useEffect(() => {
    if (teamsUserIsNotIn.data) {
      const options = teamsUserIsNotIn?.data?.map(team => ({ name: `${team.id} - ${team.name}`, value: team.id }))
      options.unshift({ name: "None", value: -1, disabled: true })
      console.log("options", options)
      setTeamOptions(options)
    }
  }, [teamsUserIsNotIn.data])

  const handleUserActivity = (isCurrentlyActive: boolean) => {
    if (isCurrentlyActive) {
      deactivateUserMutator.mutate({ id: userId })
    } else {
      activateUserMutator.mutate({ id: userId })
    }
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Text variant="h2" className={classes.mainHeading}>
            {t('admin_users_and_teams.user_detail')}
          </Text>
          <Grid container spacing={2}>

            <Grid item xs={6}>
              <Text variant="h4" className={classes.heading}>
                {t('admin_users_and_teams.user_data')}
              </Text>

              {data ?
                <Grid container>
                  <Grid item xs={4}>
                    {t('id')}:
                  </Grid>
                  <Grid item xs={8} className={classes.values}>
                    {data?.id}
                  </Grid>
                  <Grid item xs={4}>
                    {t('first_name')}:
                  </Grid>
                  <Grid item xs={8} className={classes.values}>
                    {data?.firstName}
                  </Grid>

                  <Grid item xs={4}>
                    {t('last_name')}:
                  </Grid>
                  <Grid item xs={8} className={classes.values}>
                    {data?.lastName}
                  </Grid>

                  <Grid item xs={4}>
                    {t('email')}:
                  </Grid>

                  <Grid item xs={8} className={classes.values}>
                    {data?.email}
                  </Grid>
                </Grid>

                : <Skeleton variant="rect" width="100%" height={100} style={{ marginTop: 10 }} />}
              <br />

              {data ?
                <Grid container>
                  <Grid item xs={4}>
                    {t('uid')}:
                  </Grid>
                  <Grid item xs={8} className={classes.values}>
                    {data?.ssoData?.sub}
                  </Grid>


                  <Grid item xs={4}>
                    {t('roles')}:
                  </Grid>
                  <Grid item xs={8} className={classes.values}>
                    {data?.ssoData?.role}
                  </Grid>


                  <Grid item xs={4}>
                    {t('last_auth_time')}:
                  </Grid>
                  <Grid item xs={8} className={classes.values}>

                  {moment(data?.ssoData?.auth_time * 1000).format('DD.MM.YYYY @ hh:mm:ss')}
                  </Grid>


                  <Grid item xs={4}>
                    {t('manager_uid')}:
                  </Grid>
                  <Grid item xs={8} className={classes.values}>
                    {data?.ssoData?.manageruid}
                  </Grid>


                  <Grid item xs={4}>
                    {t('department')}:
                  </Grid>
                  <Grid item xs={8} className={classes.values}>
                    {data?.ssoData?.department}
                  </Grid>


                  <Grid item xs={4}>
                    {t('department_name')}:
                  </Grid>
                  <Grid item xs={8} className={classes.values}>
                    {data?.ssoData?.departmentname}
                  </Grid>

                </Grid>

                : <Skeleton variant="rect" width="100%" height={150} style={{ marginTop: 10 }} />}
            </Grid>


            <Grid item xs={6}>
              <Text variant="h4" className={classes.heading}>
                {t('ssoData')}
              </Text>
              {data ? <>
                {data?.ssoData ? (
                  Object.keys(data.ssoData).map((key) => (
                    <div>
                      {key}: {data?.ssoData[key]}
                    </div>
                  ))
                ) : (
                  <b> N/A</b>
                )}
              </>
                : <Skeleton variant="rect" width="100%" height={250} style={{ marginTop: 10 }} />}
            </Grid>

            <Grid item xs={12}>
              {data ?
                <div className={classes.activity}>
                  <CustomSwitch size="small" value={active} onClick={() => {
                    handleUserActivity(data?.active)
                    setActive(!active)
                  }} />
                  <p className={active ? classes.active : classes.inactive}>{t(active ? 'user_active' : 'user_inactive')}</p>
                </div>
                : <Skeleton variant="rect" width="40%" height={30} style={{ marginTop: 10 }} />}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.teams}>
                <Text variant="h4" className={classes.heading}>
                  {t('teams')}
                </Text>
                {teams?.data ?
                  <>
                    {teams?.data?.map((team) => (
                      <div key={`team-${team.id}`} className={classes.team}>
                        <CancelIcon
                          className={classes.cancelIcon}
                          onClick={() => {
                            removeUserTeam.mutate({ teamId: team.id, userId: data?.id })
                          }}
                        />
                        <div>
                          {team.id} - {team.name}
                        </div>
                      </div>
                    ))}
                  </>
                  : <Skeleton variant="rect" width="100%" height={150} style={{ marginTop: 10 }} />}
              </div>
            </Grid>
            <Grid item xs={12}>

              <Text variant="h4" className={classes.heading}>
                {t('admin_users_and_teams.add_user_to_team')}
              </Text>

              {teams?.data ?
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    {teamOptions ?
                      <MaterialSelect
                        classNameSelect={classes.select}
                        value={teamIdToAdd}
                        label={t('admin_users_and_teams.team')}
                        onChange={(val) => setTeamIdToAdd(val)}
                        options={teamOptions}
                      /> : null}
                  </Grid>

                  <Grid item xs={2}>
                    <CustomButton
                      onClick={() => {
                        if (teamIdToAdd !== -1) {
                          addUserTeam.mutate({ teamId: teamIdToAdd, userId: data?.id })
                        }
                      }}
                    >
                      {t('add')}
                    </CustomButton>
                  </Grid>
                </Grid>
                : <Skeleton variant="rect" width="40%" height={50} style={{ marginTop: 10 }} />}
            </Grid>
          </Grid>
        </>,
      )}
    </ProtectedPage>
  )
}

export default UserDetailPage
